import React from "react";
import { Box, Modal } from "@mui/material";

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  handleClose,
  title,
  children,
  style,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          height: "auto",
          bgcolor: "background.paper",
          border: "none",
          boxShadow: 24,
          p: 4,
          ...style,
        }}
      >
        <Box>{children}</Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
