import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import PheonixTextField from "../Components/PheonixTextField";
import SubmitButton from "../Components/PheonixButton";
import PheonixPaper from "../Components/PheonixPaper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import PheonixContainer from "../Components/PheonixContainer";
import { Stack } from "@mui/material";
import headerlogo from "../assets/headerlogo.png";
import password_reset_background from "../assets/password_reset_background.jpg";
import { useTheme, useMediaQuery } from "@mui/material";
import Alert from "@mui/material/Alert";
import { TEXT } from "../constant";
import { useSession } from "../context/sessioncontext";
import { config } from "../config";
import axios from "axios";

const Item = styled("div")({
  marginBottom: "5px",
  padding: "5px",
  textAlign: "center",
});

const Resetpwd: React.FC = () => {
  const theme = useTheme();
  const Navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [Error, setError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const { login } = useSession();

const handlechangepwd = async () => {
  if (
    password.length < 8 ||
    confirmpassword.length < 8 ||
    password !== confirmpassword
  ) {
    setErrorMessage(TEXT.REVIEW);
    setShowAlert(true);

    switch (true) {
      case password === "":
        setError(TEXT.BLANK);
        break;
      case confirmpassword === "":
        setError("");
        setConfirmError(TEXT.BLANK);
        break;
      case password !== "" && password.length < 8:
        setConfirmError("");
        setError(TEXT.PWDMIN);
        break;
      case confirmpassword !== "" && confirmpassword.length < 8:
        setError("");
        setConfirmError(TEXT.PWDMIN);
        break;
      case password !== "" &&
        confirmpassword !== "" &&
        password !== confirmpassword:
        setError("");
        setConfirmError(TEXT.PWDNOTMATCH);
        break;
      default:
        setError("");
        setConfirmError("");
    }
    return; 
  }

  try {
    const response = await axios.put(
      `${config.userURL}/users/password`,
      {
        authenticity_token: "", 
        user: {
          reset_password_token: "",
          password: password, 
          password_confirmation: confirmpassword, 
          commit: "Change my password",
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (
      typeof response.data === "string" &&
      response.data.includes("not found")
    ) {
      setErrorMessage("Email not found. Please use a valid email.");
      setShowAlert(true);
    } else {
      Navigate("/"); 
      login(); 
    }
  } catch (error) {
    console.error("Error resetting password:", error);
    setErrorMessage("Failed to reset password. Please try again.");
    setShowAlert(true);
  }
};

  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
    login();
  };
  const handleconfirmpassword = (e: ChangeEvent<HTMLInputElement>) => {
    setconfirmpassword(e.target.value);
    login();
  };

  const paperHeight = ShowAlert
    ? isMobile
      ? "380px"
      : "360px"
    : isMobile
    ? "320px"
    : "300px";

  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
          width: "100%",
          height: "100%",
          padding: "10px 0",
          position: "relative",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <img
          src={headerlogo}
          alt="Logo"
          style={{
            width: isMobile ? "15%" : "8%",
            height: "auto",
            marginLeft: isMobile ? "20px" : "48px",
          }}
        />
      </div>
      <div
        style={{
          backgroundImage: `url(${password_reset_background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <PheonixContainer maxWidth="xs">
          <PheonixPaper
            style={{
              height: paperHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: isMobile ? "280px" : "380px",
              borderRadius: "0",
              marginTop: "130px",
              marginLeft: isMobile ? "auto" : "initial",
              marginRight: isMobile ? "auto" : "initial",
            }}
          >
            <Stack direction="column">
              <Box component="form">
                <Item>
                  <Typography
                    style={{
                      color: "#676a6c",
                      fontFamily: "sans-serif",
                      marginTop: "10px",
                      fontSize: "24px",
                      fontWeight: 0,
                    }}
                  >
                    {TEXT.CHANGEPWD}
                  </Typography>
                </Item>
                {ShowAlert && ErrorMessage && (
                  <Item>
                    <Alert severity="error" style={{ color: "#a94442" }}>
                      {ErrorMessage}
                    </Alert>
                  </Item>
                )}
                <Item>
                  <Typography
                    style={{
                      color: "#676a6c",
                      fontFamily: "sans-serif",
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {TEXT.PWD}
                  </Typography>
                  <PheonixTextField
                    id="outlined-error"
                    type="password"
                    value={password}
                    variant="outlined"
                    onChange={handlepasswordChange}
                    style={{
                      width: isMobile ? "260px" : "350px",
                      height: "35px",
                      color: "#676a6c",
                      fontFamily: "open-sans",
                    }}
                  />
                </Item>
                {Error && (
                  <Typography
                    style={{
                      color: "#a94442",
                      fontFamily: "sans-serif",
                      fontSize: "12px",
                      textAlign: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {Error}
                  </Typography>
                )}
                <Item>
                  <Typography
                    style={{
                      color: "#676a6c",
                      fontFamily: "sans-serif",
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {TEXT.CPWD}
                  </Typography>
                  <PheonixTextField
                    id="outlined-error"
                    type="password"
                    value={confirmpassword}
                    onChange={handleconfirmpassword}
                    variant="outlined"
                    style={{
                      width: isMobile ? "260px" : "350px",
                      height: "35px",
                    }}
                  />
                </Item>
                {confirmError && (
                  <Typography
                    style={{
                      color: "#a94442",
                      fontFamily: "sans-serif",
                      fontSize: "12px",
                      textAlign: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {confirmError}
                  </Typography>
                )}
                <Item>
                  <SubmitButton
                    label={"Change my password"}
                    style={{
                      width: isMobile ? "260px" : "350px",
                      textTransform: "none",
                      color: "#FFFFFF",
                      backgroundColor: "#1ab394",
                    }}
                    onClick={handlechangepwd}
                  />
                </Item>
              </Box>
            </Stack>
          </PheonixPaper>
        </PheonixContainer>
      </div>
    </>
  );
};
export default Resetpwd;
