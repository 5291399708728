import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PheonixSideBarProps from "../interfaces/PheonixSideBarProps";

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({
  icon,
  text,
  to,
  selected,
  onClick,
  isOpen,
  sx,
}) => {
  const location = useLocation();
  const isActive = location.pathname === to || selected;

  return (
    <ListItem
      sx={{
        textDecoration: "none",
        backgroundColor: isActive ? "#293846" : "transparent",
        color: isActive ? "#FFFFFF" : "#a7b1c2",
        marginTop: isOpen ? "0px" : "2%",
        position: "relative",
        "&:hover": {
          backgroundColor: "#293846",
          color: "#FFFFFF",
        },
        fontSize: "13px",
        fontWeight: "600",
        width: "230px",
        fontFamily: "sans-serif",
        "& .MuiTypography-root": {
          fontSize: "13px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        },
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "3px",
          backgroundColor: isActive ? "#1ab394" : "transparent",
        },
      }}
      component={to ? Link : "div"}
      to={to}
      onClick={onClick}
      style={sx}
    >
      <ListItemIcon
        sx={{
          color: "inherit", 
          minWidth: "30px",
          "&:hover": {
            color: "#FFFFFF", 
          },
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default PheonixSideBar;
