import React, { ChangeEvent, FocusEvent, ReactNode } from "react";
import TextField from "@mui/material/TextField";
import PheonixTextFieldProps from "../interfaces/PheonixTextFieldProps";

const PheonixTextField: React.FC<PheonixTextFieldProps> = ({
  id,
  type,
  value,
  placeholder,
  label,
  variant,
  disabled,
  onChange,
  onFocus,
  onBlur,
  endAdornment,
  style,
  required,
  rememberMe,
  className,
  error,
  helperText,
}) => {
  return (
    <TextField
      id={id}
      type={type}
      value={value}
      placeholder={placeholder}
      label={label}
      variant={variant}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: endAdornment,
        className: className,
        style: style,
      }}
      disabled={disabled}
      required={required}
      sx={{
        fontFamily: "open sans",
        fontWeight: 100,
        "& label.Mui-focused": {
          color: "#676a6c",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "green",
        },
        "& .MuiInputLabel-root": {
          color: "#676a6c",
          fontSize: "14px",
          paddingBottom: "5px",
        },
        "& .MuiInputLabel-outlined": {
          color: "#676a6c",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#1ab394!important",
          },
        },
      }}
    />
  );
};
export default PheonixTextField;
