import React, { useState } from "react";
import { DataGrid, GridColDef, GridRowModel,GridRowId } from "@mui/x-data-grid";
import PheonixDataGridProps from "../interfaces/PheonixGridProps";
import Box from "@mui/material/Box";
import { MODALTEXT } from "../constant";
import { Typography } from "@mui/material";

interface CustomDataGridProps extends PheonixDataGridProps {
  onSelect?: (selection: any) => void;
  onEdit?: (row: GridRowModel) => void;
}

export const PheonixDataGrid: React.FC<CustomDataGridProps> = ({
  rows,
  columns,
  title,
  checkboxSelections,
  pageSizeOptions,
  onSelect,
  onEdit,
  ...props
}) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10 || 30 || 50,
    page: 0,
  });
  const [editedRows, setEditedRows] = useState<any>({});
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const editableColumns = columns.map((column: GridColDef) => ({
    ...column,
    editable: true,
  }));

  function DataGridTitle() {
    return (
      <>
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "left",
            padding: "10px 8px 0px 8px",
            backgroundColor: "#ffffff",
            alignItems: "center",
            border: "none",
            borderColor: "transparent",
            borderStyle: "solid solid none",
            marginLeft: "10px",
            marginTop: "10px",
            marginRight: "10px",
            overflow: "auto",
          }}
        >
          <Typography style={{ color: "#293846", fontSize: "16px" }}>
            {title}
          </Typography>
        </Box>
      </>
    );
  }

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    setEditedRows((prevRows: any) => ({
      ...prevRows,
      [newRow.id]: newRow,
    }));
    if (onEdit) {
      onEdit(newRow);
    }
    return newRow;
  };

const handleRowSelectionChange = (newSelection: GridRowId[]) => {
    const filteredSelection = newSelection.filter(id => id !== null && id !== undefined);
    setSelectionModel(filteredSelection);
    if (onSelect) {
        onSelect(filteredSelection.map(id => id));
    }
};
  return (
    <div style={{ width: "100%", height: "100vh", display: "contents" }}>
      <DataGridTitle />
      {rows.length === 0 ? (
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "left",
            padding: "10px 8px 0px 8px",
            backgroundColor: "#ffffff",
            alignItems: "center",
            border: "none",
            borderColor: "transparent",
            borderStyle: "solid solid none",
            marginLeft: "10px",
            marginTop: "10px",
            marginRight: "10px",
            overflow: "auto",
          }}
        >
          <Typography
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              color: "#676a6c",
              fontsize: "10px",
              fontFamily: "sans-serif",
            }}
          >
            {MODALTEXT.NODATA} {title}
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={rows}
          columns={editableColumns}
          paginationModel={paginationModel}
          pageSizeOptions={pageSizeOptions || [5, 10, 20, 30]}
          onPaginationModelChange={setPaginationModel}
          checkboxSelection={checkboxSelections}
          processRowUpdate={handleProcessRowUpdate}
          onRowSelectionModelChange={handleRowSelectionChange} 
          rowSelectionModel={selectionModel}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          hideFooterSelectedRowCount
          sx={{
            boxShadow: 2,
            border: 2,
            overflow: "auto",
            "& .MuiDataGrid-columnHeaders": {
              color: "#428bca",
              fontSize: "13px",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "13px",
              lineHeight: "18px",
            },
            "& .MuiDataGrid-iconButtonContainer": {
              display: "none",
              overflow: "auto",
            },
            "& .MuiDataGrid-sortIcon": {
              display: "none",
              overflow: "auto",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
              overflow: "auto",
            },
            ".MuiDataGrid-virtualScrollerContent": {
              width: "950px",
              height: "500px",
              minHeight: "auto",
              overflow: "auto",
            },
             '& .MuiTablePagination-selectLabel': {
              display: 'block',
            },
  
            '& .MuiTablePagination-input': {
              display: 'block',
            },
            '& .MuiTablePagination-select': {
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            },
            '@media (max-width: 425px) and (max-height: 608px)': {
              '& .MuiDataGrid-root': {
                minWidth: '100%',
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                overflow: 'visible',
              },
              '& .MuiDataGrid-toolbarContainer': {
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              },
              '& .MuiTablePagination-selectLabel': {
                display: 'block',
              },
              '& .MuiTablePagination-input': {
                display: 'block',
              },
              '& .MuiTablePagination-select': {
                minHeight: '32px',
                fontSize: '0.875rem',
              },
            },
            '@media (max-width: 600px)': {
              '& .MuiDataGrid-root': {
                minWidth: '100%',
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                overflow: 'visible',
              },
              '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel': {
                display: 'block',
              },
              '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-input': {
                display: 'block',
              },
              '& .css-194a1fa-MuiSelect-select-MuiInputBase-input':{
                width: "50%",
              },
              '& .css-1cccqvr':{
                width: "50%",
              },
              '& .css-whi0l6 .MuiTablePagination-selectLabel': {
                display: 'block',
              },
              '& .css-whi0l6 .MuiTablePagination-input': {
                display: 'block',
              },
              '& .MuiTablePagination-input': {
                display: 'block',
              },
            },
          }}
          {...props}
        />
      )}
    </div>
  );
};
