import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Grid,
  Typography,
  Divider,
  Paper,
  Box,
  Autocomplete,
  Alert,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import PheonixModalProps from "../interfaces/PheonixModel";
import CloseIcon from "@mui/icons-material/Close";
import { BUTTON, TEXT, URL } from "../constant";
import googlestore from "../assets/google_PlayStore_logo.png";
import appstore from "../assets/IOS_logo.png";
import Papa from "papaparse";
import PheonixTable from "./PheonixTable";
import "../pages/styles.css";
import { useTheme, useMediaQuery } from "@mui/material";
import axios from "axios";
import { api } from "../api";
import { config } from "../config";
import { useNavigate, useLocation } from "react-router-dom";

const PheonixWizardModal = <T,>({
  open,
  onClose,
  title,
}: PheonixModalProps<T>) => {
  const [step, setStep] = useState(0);
  const handleSteppage = (newStep: number) => {
    setStep(newStep);
  };
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const handleChooseFile = () => {
    document.getElementById("file-upload")?.click();
  };
  const userId = sessionStorage.getItem("user_id");
  const [tableData, setTableData] = useState<any[]>([]);
  const [columns, setColumns] = useState<{ id: string; label: string }[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [backdropOpen, setBackdropOpen] = useState(false);
  const token = localStorage.getItem("authtoken");
  const [allContactsId, setAllContactsId] = useState("");
  const [message, setMessage] = useState<string>("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const MAX_FILE_SIZE = 3 * 1024 * 1024;
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setShowAlert(false);

      if (file.size <= MAX_FILE_SIZE) {
        setSelectedFile(file);
        setUploadSuccess(false);
        setProgress(0);

        let uploadProgress = 0;
        const interval = setInterval(() => {
          uploadProgress += 10;
          setProgress(uploadProgress);
          if (uploadProgress >= 100) {
            clearInterval(interval);
            setUploadSuccess(true);
          }
        }, 500);

        Papa.parse(file, {
          header: true,
          complete: (results: any) => {
            if (results.data.length > 0) {
              setTableData(results.data);
              const headers = Object.keys(results.data[0]);
              const newColumns = headers.map((header) => ({
                id: header,
                label: header.charAt(0).toUpperCase() + header.slice(1),
              }));
              setColumns(newColumns);
              setShowAlert(false);
            } else {
              setShowAlert(true);
              setErrorMessages(TEXT.NOFILE);
              setTimeout(() => {
                setShowAlert(false);
                setErrorMessages("");
              }, 5000);
            }
          },
          error: (error: any) => {
            setShowAlert(true);
            setErrorMessages(TEXT.FILEERROR);
          },
        });
      } else {
        setShowAlert(true);
        setErrorMessages(TEXT.FILELARGE);
        setSelectedFile(null);
        setProgress(0);
      }
    } else {
      setSelectedFile(null);
      setProgress(0);
    }
  };

  useEffect(() => {
    fetchoption();
  }, []);

  const fetchoption = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/v1/lists`, {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const lists = response.data.lists.map(
        (item: { name: string; id: string }) => ({
          name: item.name,
          id: item.id,
        })
      );

      const allContactsList = lists.find(
        (list: { name: string }) => list.name === "All Contacts"
      );
      var allContactsId = allContactsList ? allContactsList.id : "";
      setAllContactsId(allContactsId);
      let userIds = response.data.lists.map(
        (item: { user_id: string }) => item.user_id
      );
      const firstUserId = userIds.length > 0 ? userIds[0] : null;
      if (firstUserId) {
        sessionStorage.setItem("user_id", firstUserId);
      }
    } catch (error) {
      console.error("Error fetching options", error);
    }
  };

  const handleImportCSV = async (uploadfile: File | null) => {
    if (!uploadfile) {
      return;
    }
    const query = `
      mutation UploadContacts($file: Upload!, $user_id: ID!, $list_id: ID!) {
        uploadContacts(file: $file, user_id: $user_id, list_id: $list_id) {
          success
          message
        }
      }
    `;

    const listIds = [allContactsId];
    const listIdString = listIds.filter(Boolean).join(",");

    const variables = {
      file: null,
      user_id: userId,
      list_id: listIdString,
    };

    const formData = new FormData();
    formData.append("operations", JSON.stringify({ query, variables }));
    formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
    formData.append("0", uploadfile);

    try {
      setBackdropOpen(true);
      const response = await axios.post(api.baseUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-cotg-authtoken": token,
        },
      });

      if (response.data.errors) {
        console.error("GraphQL Errors", response.data.errors);
        setMessage(response.data.errors);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
        setSelectedFile(null);
        setTableData([]);
      } else {
        setTimeout(() => {
          setBackdropOpen(false);
          var { success, message } = response.data.data.uploadContacts;
          if (success) {
            sessionStorage.setItem("messageimport", TEXT.IMPORTMSG);
            navigate("/imports/new");
          } else {
            if (message === TEXT.IMPORT_TEXT_ALREADY) {
              sessionStorage.setItem("messageerrorimport", message);
              navigate("/imports/new");
            } else {
              setShowAlert(true);
              setErrorMessages(message);
              setTimeout(() => {
                setShowAlert(false);
                setErrorMessages("");
              }, 5000);
              setSelectedFile(null);
              setTableData([]);
            }
          }
        }, 10000);
      }
    } catch (error) {
      console.error("Network Error:", error);
      setBackdropOpen(false);
    }
  };
  const handleNext = () => {
    setStep((prevStep) => (prevStep === 2 ? 0 : prevStep + 1));
  };

  const handlePrevious = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));
  };

  const handleButtonClick = () => {
    handleImportCSV(selectedFile);
  };
  const handleClose = () => {
    setSelectedFile(null);
    setTableData([]);
    onClose();
  };

  return (
    <>
      <div style={{ color: "#f3f3f4", backgroundColor: "#F3F3F4" }}>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "white",
              width: isMobile ? "95%" : "90%",
              height: "auto",
              boxShadow: "0px 4px 4px 0px #00000040",
              border: "1px solid #0000001F",
              margin: "0px",
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: isMobile ? "18px" : "24px",
              color: "#999c9e",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {title}
          </DialogTitle>
          <Typography
            sx={{
              fontSize: isMobile ? "11px" : "12px",
              color: "#999c9e",
              justifyContent: "center",
              textAlign: "center",
              margin: isMobile ? "0px 20px 0px 20px " : "10px",
            }}
          >
            {TEXT.SUB_TITLE}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: isMobile ? 2 : 10,
              top: 0,
              color: "#999c9e",
            }}
          >
            <CloseIcon sx={{ color: "#999c9e", fontSize: "1rem" }} />
          </IconButton>
          <Divider
            style={{
              marginBottom: "1rem",
              marginTop: "0.5rem",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0px 40px 0px 20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: isMobile ? "12px" : "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSteppage(0)}
                sx={{
                  color: "#ffffff",
                  width: isMobile ? "30%" : "40%",
                  fontSize: isMobile ? "10px" : "auto",
                  textTransform: "none",
                  justifyContent: "left",
                  backgroundColor: step === 0 ? "#1ab394" : "#6ED1BD",
                  padding: isMobile ? "6px" : "8px 8px 8px 8px",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.IMPORT_BUTTON}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSteppage(1)}
                disabled={isFirstRender}
                sx={{
                  color: "#ffffff",
                  width: isMobile ? "30%" : "40%",
                  fontSize: isMobile ? "10px" : "auto",
                  textTransform: "none",
                  justifyContent: "left",
                  backgroundColor: step === 1 ? "#1ab394" : "#6ED1BD",
                  padding: isMobile ? "4px" : "8px 10px 8px 16px",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.APPS}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSteppage(2)}
                disabled={isFirstRender}
                sx={{
                  color: "#ffffff",
                  width: isMobile ? "30%" : "40%",
                  fontSize: isMobile ? "10px" : "auto",
                  textTransform: "none",
                  justifyContent: "left",
                  backgroundColor: step === 2 ? "#1ab394" : "#6ED1BD",
                  padding: isMobile ? "4px" : "8px 10px 8px 16px",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.GET_STARTED}
              </Button>
            </Box>
            <Paper
              elevation={0}
              style={{
                padding: "10px",
                margin: "20px 0px 0px 0px",
                width: "95%",
                height: "208px",
                color: "#999c9e",
                backgroundColor: "#EEEEEE",
                overflowY: "auto",
              }}
            >
              {step === 0 && (
                <>
                  <Typography
                    variant="h6"
                    style={{
                      color: " #676a6c",
                      fontSize: isMobile ? "14px" : "16px",
                      padding: isMobile ? "0px" : "20px 0px 0px 30px",
                      fontWeight: "600",
                    }}
                  >
                    {TEXT.IMPORT_CONTACT}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676a6c",
                      fontSize: isMobile ? "12px" : "13px",
                      padding: isMobile ? "0px" : "20px 0px 0px 30px",
                    }}
                  >
                    {TEXT.CSV_CONTENT_1}
                    <br />
                    {TEXT.CSV_CONTENT_2}
                    <br />
                    {TEXT.CSV_CONTENT_3}
                    <br />
                  </Typography>
                  {!selectedFile && (
                    <form style={{ margin: isMobile ? "10px" : "30px" }}>
                      <div
                        className="input_container"
                        style={{
                          border: "1px solid #aaaaaa",
                          width: isMobile ? "100%" : isMobile ? "90%" : "45%",
                        }}
                      >
                        <>
                          <label
                            className="custom-file-upload"
                            onClick={handleChooseFile}
                            style={{
                              display: "inline-block",
                              padding: "5px 15px",
                              border: "1px solid #293846",
                              backgroundColor: "#f3f3f4",
                              cursor: "pointer",
                              fontSize: isMobile ? "12px" : "13px",
                              lineHeight: "1",
                              fontFamily: "sans-serif",
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.backgroundColor = "#e0e0e0";
                              e.currentTarget.style.borderColor = "#293846";
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.backgroundColor = "#f3f3f4";
                              e.currentTarget.style.borderColor = "#293846";
                            }}
                          >
                            Choose file
                          </label>
                          <span
                            style={{
                              fontSize: isMobile
                                ? "10px"
                                : isMobile
                                ? "12px"
                                : "14px",
                            }}
                          >
                            {"No file chosen"}
                          </span>
                          <input
                            type="file"
                            id="file-upload"
                            accept=".csv"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </>
                      </div>
                    </form>
                  )}
                  {message && ShowAlert && (
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={() => setShowAlert(true)}
                        >
                          <CloseIcon
                            sx={{ fontSize: "small", color: "#676a6c" }}
                          />
                        </IconButton>
                      }
                      style={{ color: "#3c763d" }}
                    >
                      {message}
                    </Alert>
                  )}
                  {errorMessages && ShowAlert && (
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={() => setShowAlert(false)}
                        >
                          <CloseIcon
                            sx={{ fontSize: "small", color: "#676a6c" }}
                          />
                        </IconButton>
                      }
                      style={{ color: "#A94342", background: "#F2DEDD" }}
                    >
                      {errorMessages}
                    </Alert>
                  )}
                  {selectedFile && (
                    <Typography
                      variant="h6"
                      style={{
                        color: " #676a6c",
                        fontSize: isMobile ? "14px" : "16px",
                        padding: isMobile ? "0px" : "20px 0px 0px 30px",
                        fontWeight: "600",
                      }}
                    >
                      {TEXT.PREVIEW}
                    </Typography>
                  )}
                  {selectedFile && (
                    <Typography
                      variant="h6"
                      style={{
                        color: "#676a6c",
                        fontSize: isMobile ? "12px" : "13px",
                        padding: isMobile ? "0px" : "5px 0px 0px 30px",
                      }}
                    >
                      {TEXT.PREVIEW_CONTENT}
                    </Typography>
                  )}
                  <div style={{ fontSize: isMobile ? "8px" : "10px" }}>
                    {selectedFile && (
                      <Box sx={{ minHeight: "50px" }}>
                        <div style={{ height: "100px", overflowY: "scroll" }}>
                          <PheonixTable columns={columns} data={tableData} />
                        </div>
                      </Box>
                    )}

                    {selectedFile && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleButtonClick}
                        sx={{
                          color: "#ffffff",
                          backgroundColor: "#1ab394",
                          textTransform: "none",
                          fontSize: isMobile ? "11px" : "auto",
                          margin: isMobile ? "5px" : "20px 0px 0px 20px ",
                          "&:hover": {
                            backgroundColor: "#1ab394",
                          },
                        }}
                      >
                        {BUTTON.UPLOAD}
                      </Button>
                    )}
                    <a
                      href={URL.MAILID}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: "#2A6496",
                          fontSize: isMobile ? "11px" : "13px",
                          padding: isMobile ? "1px" : "20px 0px 0px 20px",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        {TEXT.HAVING_TROUBLE}
                      </Typography>
                    </a>
                  </div>
                </>
              )}
              {step === 1 && (
                <>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676A6C",
                      fontSize: isMobile ? "14px" : "16px",
                      fontWeight: "600",
                      padding: isMobile ? "1px" : "10px 0px 0px 10px",
                    }}
                  >
                    {TEXT.DOWNLOAD_APP}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676A6C",
                      fontSize: isMobile ? "12px" : "13px",
                      padding: isMobile ? "1px" : "5px 0px 0px 10px",
                    }}
                  >
                    {TEXT.LINKING_APP}
                  </Typography>
                  <Tooltip title="In progress" arrow>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googlestore}
                      alt="googlestore"
                      style={{
                        margin: "10px 0px 0px 10px",
                        width: "140px",
                        height: "45px",
                      }}
                    />
                  </a>
                  </Tooltip>
                  <Tooltip title="In progress" arrow>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appstore}
                      alt="appstore"
                      style={{
                        marginLeft: "10px",
                        width: "140px",
                        height: "45px",
                      }}
                    />
                  </a>
                  </Tooltip>
                </>
              )}
              {step === 2 && (
                <>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676a6c",
                      fontSize: isMobile ? "14px" : "16px",
                      padding: isMobile ? "1px" : "10px 0px 0px 10px",
                      fontWeight: "600",
                    }}
                  >
                    {TEXT.START_CALL}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#676a6c",
                      fontSize: isMobile ? "11px" : "13px",
                      padding: isMobile ? "1px" : "10px 0px 0px 10px",
                    }}
                  >
                    {TEXT.INFO_DETAILS}
                  </Typography>
                </>
              )}
            </Paper>

            <Box
              style={{
                padding: "10px",
                margin: "0px 0px 0px 0px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handlePrevious}
                disabled={step === 0}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#1ab394",
                  textTransform: "none",
                  fontSize: isMobile ? "10px" : "auto",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {BUTTON.PERVIOUS}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleNext}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#1ab394",
                  textTransform: "none",
                  fontSize: isMobile ? "10px" : "auto",
                  "&:hover": {
                    backgroundColor: "#1ab394",
                  },
                }}
              >
                {step === 2 ? "Finish" : BUTTON.NEXT}
              </Button>
            </Box>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backdropOpen}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" />
                <Typography sx={{ mt: 2, color: "#FFFFFF" }}>
                  {TEXT.FILE_UPLOAD}
                </Typography>
              </Box>
            </Backdrop>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default PheonixWizardModal;
