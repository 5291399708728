import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBarProps from "../interfaces/AppBarProps";
import PheonixSearch from "./PheonixSearch";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Typography, IconButton, Box, Divider } from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { TEXT } from "../constant";
import { useSession } from "../context/sessioncontext";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "../pages/sidebarcontext"
import axios from "axios";
import { config } from "../config";

const AppBar: React.FC<AppBarProps> = ({ onToggle, isSidebarOpen }) => {
  const [email, setEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { logout } = useSession();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const { isOpen, toggleSidebar } = useSidebar();
  const token = localStorage.getItem("authtoken");

  const iconComponent = (
    <Box
      border={0.5}
      borderRadius={1}
      p={0}
      style={{ color: "#1ab394", backgroundColor: "#1ab394", 
      }}
    >
      <IconButton style={{ color: "#FFFFFF" }} onClick={toggleSidebar}>
        {isOpen ? (
          <ArrowBackOutlinedIcon sx={{ fontSize: "1.2rem" }}/>
        ) : (
          <ArrowForwardOutlinedIcon  sx={{ fontSize: "1.2rem" }}/>
        )}
      </IconButton>
    </Box>
  );


  const mobileiconComponent = (
    <Box
      border={0.5}
      borderRadius={1}
      p={0}
      style={{ color: "#1ab394", backgroundColor: "#1ab394" }}
    >
      <IconButton style={{ color: "#FFFFFF" }} onClick={toggleSidebar}>
        {isOpen ? (
           <ArrowBackOutlinedIcon sx={{ fontSize: "0.9rem" }}/>
        ) : (
          <ArrowForwardOutlinedIcon sx={{ fontSize: "0.9rem" }}/>
        )}
      </IconButton>
    </Box>
  );

  const titleMarginLeft = isOpen ? (isMobile || isTablet ? "0px" : "230px") : "70px";
  const navigate = useNavigate();
  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    setEmail(storedEmail || "Guest");
  }, []);
  const handleLogout = () => {
    logout();
    navigate("/users/sign_in");
    sessionStorage.removeItem("email");
    sessionStorage.setItem("message", TEXT.LOGOUTMSG);
  };
  const handlesettings = () => {
    navigate("/users/edit");
  };


  const fetchoption = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/v1/lists`, {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const userIds = response.data.lists.map(
        (item: { user_id: string }) => item.user_id
      );
      const firstUserId = userIds.length > 0 ? userIds[0] : null;
      if (firstUserId) {
        sessionStorage.setItem("user_id", firstUserId);
      }
    } catch (error) {
      console.error("Error fetching options", error);
    }
  };
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("searchQuery") || "";
    setSearchQuery(query);
    fetchoption();
  }, [location.search]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    navigate(`/search?searchQuery=${query}`);
  };

  return (
    <div
      style={{
        backgroundColor: "#f3f3f4",
        height:  isMobile ? "60px" : "48px" ,
        padding: "15px",
        borderBottom: isMobile ? "0":"1px solid #ccc",
        width: !isOpen && isMobile? "0":isMobile  ? "0" : "98%",
        display: "flex",
        alignItems: isMobile ? "flex-start" : "center",
        justifyContent: "space-between",
        position: "relative",
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          marginLeft: titleMarginLeft,
          marginRight :!isOpen && isMobile? "40%": isMobile ? "40%" : 0,
        }}
      >
         {isTablet || isMobile ? mobileiconComponent : iconComponent}
        { !isMobile&& !isTablet &&
        <PheonixSearch
          onSearch={handleSearch}
          onChange={(query) => setSearchQuery(query)}
        />
      }
      </div>
      <div
        style={{ display: "flex", alignItems:  isMobile ? "flex-start" : "center",
         marginRight: !isOpen && isMobile?"10px":"40px",
       }}
      > 
        <Typography
          variant="body1"
          style={{ color: "#999c9e",
          fontSize:  "14px",
          cursor: "pointer",
          marginLeft:  !isOpen && isMobile? "5px":isMobile ? "10px":0,
          fontWeight:"500",
          paddingTop:!isOpen && isMobile? "5px":isMobile ? "5px":"2px "
           }}
          onClick={handlesettings}
        >
          {email}
        </Typography>
        </div>
        <div>
        <div
           style={{ 
            display: "flex",
            alignItems:  "center",
            margin:isMobile && !isOpen? "50px 0px 0px -220px": isMobile ? "50px 30px 0px -250px" : 0 ,
           }}
        >
        <PowerSettingsNewIcon
          style={{ color: "#999c9e", cursor: "pointer", fontSize: "16px", fontWeight:"500" }}
          onClick={handleLogout}
        />
        <button
          style={{
            textTransform: "none",
            color: "#999c9e",
            fontFamily: "sans-serif",
            fontSize: "14px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            fontWeight:"500"
          }}
          onClick={handleLogout}
        >
          {TEXT.LOGOUT}
        </button>
        </div>
      </div>
    </div>
  );
};

export default AppBar;
