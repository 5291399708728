import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import PheonixButtonProps from '../interfaces/PheonixButtonProps';

const PheonixButton: React.FC<PheonixButtonProps> = ({ label,disabled,onClick, ...props }) => {
  return (
    <MuiButton disabled={disabled} onClick={onClick} variant="outlined"  style={{ backgroundColor:disabled ? 'lightgray' : '#1ab394',borderColor:"transparent",color: disabled ? 'darkgray' :'white', padding: '5px', width: '350px', height: '35px',borderRadius:'5px',boxSizing:'border-box',textTransform:"none"  }} {...props} >
      {label}
    </MuiButton>
  );
};
export default PheonixButton;