import React, { ReactNode, CSSProperties } from 'react';
import Container from '@mui/material/Container';
import { PheonixContainerProps } from '../interfaces/PheonixContainerProps';
import { useTheme, useMediaQuery } from "@mui/material";
const PheonixContainer: React.FC<PheonixContainerProps> = ({ children, style,maxWidth }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
      <Container
        maxWidth="xs"
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'row':'column'
        }}
      >
        {children}
      </Container>
    );
  };
  export default PheonixContainer;
  