import React, { useState, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import PheonixSearchProps from "../interfaces/PheonixSearchProps";
import PheonixPaper from "../Components/PheonixPaper";

const PheonixSearch: React.FC<PheonixSearchProps> = ({
  onChange,
  onSearch,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    onChange(query);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(searchQuery);
      setSearchQuery("");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "300px",
        height: "46px",
        padding: "5px",
        marginLeft: "10px",
      }}
    >
      <TextField
        id="standard-basic"
        variant="standard"
        value={searchQuery}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        fullWidth
        placeholder="Search for something..."
        InputProps={{ disableUnderline: true }}
      />
    </div>
  );
};

export default PheonixSearch;
