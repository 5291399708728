import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import { config } from "../config";
import { useNavigate } from "react-router-dom";
import { PheonixDataGridList } from "../Components/PheonixDataGridList";
import { Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "./sidebarcontext";

interface ListData {
  id: number;
  name: string;
  description: string | null;
  contact_size: number | null;
}

const List: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const [listData, setListData] = useState<ListData[]>([]);
  const token = localStorage.getItem("authtoken");
  const navigate = useNavigate();
  const [ShowAlert, setShowAlert] = useState(false);
  const Title = "list";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isOpen, toggleSidebar } = useSidebar();

  useEffect(() => {
    const storedMessage = localStorage.getItem("Delete");
    if (storedMessage) {
      setShowAlert(true);
      setMessage(storedMessage);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
      }, 5000);
      localStorage.removeItem("Delete");
    }
  }, []);

  useEffect(() => {
    fetchListData();
  }, []);

  useEffect(() => {
    if(listData){
    fetchListData();
    }
  }, [listData]);
  const fetchListData = () => {
    axios
      .get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      })
      .then((response) => {
        const sortedLists = response.data.lists.sort(
          (a: { name: string }, b: { name: any }) =>
            a.name.localeCompare(b.name)
        );
        setListData(sortedLists);
      })
      .catch((error) => {
        console.error("Error fetching list data:", error);
      });
  };

  const handleContactClick = (listId: number, contact: ListData) => {
    navigate(`/lists/${listId}`, { state: { listId, contact } });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width:!isOpen && isMobile ? "100" :isMobile? "100": 400,
      renderCell: (params: { row: ListData }) => {
        const contact = `${params.row.name}`;
        return (
          <div
            style={{ cursor: "pointer", color: "#428bca " }}
            onClick={() => handleContactClick(params.row.id, params.row)}
          >
            {contact}
          </div>
        );
      },
    },
    { field: "description", headerName: "Description", width: !isOpen && isMobile ? "100" :isMobile? "100": 400,},
    { field: "contact_size", headerName: "Count", width: !isOpen && isMobile ? "100" :isMobile? "100": 100},
  ];

  return (
    <div style={{ height: "120vh", backgroundColor: "#f3f3f4" }}>
      { isMobile &&
       <Divider style={{ marginTop: !isOpen && isMobile ? "1rem" :isMobile ? "1rem" :"auto" }} /> }
      {message && ShowAlert && (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#3c763d" }}
        >
          {message}
        </Alert>
      )}
      <Typography
        style={{
          padding: "10px",
          backgroundColor: "#FFFFFF",
          marginLeft: "20px",
          marginTop: "10px",
          width: !isOpen && isMobile ? "80%" :isMobile ? "84%":"96%",
        }}
      >
        List
      </Typography>
      <div
        style={{
          display: "flex",
          marginLeft: "10px",
          backgroundColor: "#f3f3f4",
        }}
      >
        <PheonixDataGridList
          columns={columns}
          rows={listData}
          pageSizeOptions={[10, 20, 30]}
          style={{
            border: "none",
            borderColor: "transparent",
            borderStyle: "solid solid none",
            backgroundColor: "#FFFFFF",
            marginLeft: "10px",
            marginTop: "10px",
            marginRight: "10px",
            maxHeight: "100vh",
            height: "auto",
          }}
          title={Title}
        />
      </div>
    </div>
  );
};

export default List;
