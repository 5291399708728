import React, { useEffect, useState } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import axios from "axios";
import { config } from "../config";
import { MODALTEXT } from "../constant";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import CallSharpIcon from '@mui/icons-material/CallSharp';
import EmailIcon from '@mui/icons-material/Email';
import MessageSharpIcon from '@mui/icons-material/MessageSharp';
import { useSidebar } from "./sidebarcontext"

const Contacts: React.FC = () => {
  const token = localStorage.getItem("authtoken");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState<number>(0);
  const location = useLocation();
  const [cardData, setCardData] = useState<any[]>([]);
  const { isOpen, toggleSidebar } = useSidebar();

  useEffect(() => {
    if (location.state?.contactId) { 
      setContactId(location.state.contactId);
      fetchData(location.state.contactId);
    }
  }, [location.state]);

  const fetchData = async (contactId: number) => {
    try {
      const response = await axios.get(
        config.baseUrl + "/v1/contacts/" + contactId,
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );
      const contact = response.data;
      const cardData = [{
      id: contact.id,
      title: `${contact.first_name} ${contact.last_name}`,
      number: contact.number, 
      email: contact.email,
      company: contact.company,
      notes: contact.notes ,
    }];
      setCardData(cardData);
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  const renderCards = (data: any[]) => {
    return data.map((card, index) => (
      <Box
        key={index}
        display="flex"
        alignItems="center"
        sx={{
          "&:hover": {
            borderColor: "#3d4592",
          },
          padding: "1rem", 
        }}
      >
        <Box flex="1">
          <Typography
            variant="h6"
            color="#2E4050"
            fontSize="16px"
            fontWeight="bold"
          >
            {card.title}
          </Typography>
          <Box display="flex" alignItems="center" mb={0.5}>
            <CallSharpIcon style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
            <Typography variant="body2" color="#2E4050" fontSize="12px">
              {card.number}
            </Typography>
          </Box>
  
       {card.email ? (
          <Box display="flex" alignItems="center" mb={0.5}>
            <EmailIcon style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
            <Typography variant="body2" color="#2E4050" fontSize="12px">
              Email: {card.email}
            </Typography>
          </Box>
        ) : null}

       {card.notes ? (
          <Box display="flex" alignItems="center">
            <MessageSharpIcon
              style={{  fontSize: "1rem", marginRight: "0.5rem" }}
            />
            <Typography variant="body2" color="#2E4050" fontSize="12px">
              Notes: {card.notes}
            </Typography>
          </Box>
          ) : null}
        </Box>
      </Box>
    ));
  };

  return (
    <div style={{ top: 0, backgroundColor: "#f3f3f4", height: "90vh"  }}>
       { isMobile &&
       <Divider style={{ marginTop: !isOpen && isMobile ? "0.5rem" :isMobile ? "0.5rem" :"auto" }} /> }
       <Paper
        sx={{
          padding: "10px",
          backgroundColor: "#ffffff",
          width:isMobile? "80%" : "60%",
          margin: "25px 50px 0px 25px",
          overflowY: "auto",
          maxHeight: "80vh", 
        }}
      >
        {renderCards(cardData)}
      </Paper>
    </div>
  );
};

export default Contacts;