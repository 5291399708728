import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import PheonixTextField from "../Components/PheonixTextField";
import SubmitButton from "../Components/PheonixButton";
import PheonixPaper from "../Components/PheonixPaper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import PheonixContainer from "../Components/PheonixContainer";
import { Stack } from "@mui/material";
import headerlogo from "../assets/headerlogo.png";
import password_reset_background from "../assets/password_reset_background.jpg";
import { useTheme, useMediaQuery } from "@mui/material";
import Alert from "@mui/material/Alert";
import { TEXT } from "../constant";
import { useSession } from "../context/sessioncontext";
import axios from "axios";
import { config } from "../config";

const Item = styled("div")({
  marginBottom: "5px",
  padding: "5px",
  textAlign: "center",
});

const Forgotpwd: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [emails, setEmail] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [Error, setError] = useState("");
  const { login } = useSession();

  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    login();
  };

  const handleLogout = () => {
    navigate("/users/sign_in");
  };

  const handleResetpwd = async () => {
    if (emails === "") {
      setErrorMessage(TEXT.REVIEW);
      setError(TEXT.BLANK);
      setShowAlert(true);
      return;
    }
  
    try {
      const response = await axios.post(
        `${config.userURL}/users/password`,
        {
          user: {
            email: emails,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
        }
      );
      if (typeof response.data === "string" && response.data.includes("not found")) {
        setErrorMessage(TEXT.EMAIL_NOT_FOUND);
        setShowAlert(true);
      } else {
        setEmail("");
        setErrorMessage("");
        sessionStorage.setItem("message", TEXT.ALERT);
        navigate("/users/sign_in");
      }
    } catch (error) {
      setErrorMessage(TEXT.EMAIL_RESET_PASSWORD);
      setShowAlert(true);
    }
  };    
  
  const handlesignup = () => {
    window.location.href = TEXT.SIGNUP_URL;
    login()
  };

  const paperHeight = ShowAlert
    ? isMobile
      ? "350px"
      : "320px"
    : isMobile
    ? "300px"
    : "260px";
  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
          width: "100%",
          height: "100%",
          padding: "10px 0",
          position: "relative",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <img
          src={headerlogo}
          alt="Logo"
          style={{
            width: isMobile ? "15%" : "8%",
            height: "auto",
            marginLeft: isMobile ? "20px" : "48px",
          }}
        />
      </div>
      <div
        style={{
          backgroundImage: `url(${password_reset_background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <PheonixContainer maxWidth="xs">
          <PheonixPaper
            style={{
              height: paperHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: isMobile ? "280px" : "380px",
              borderRadius: "0",
              marginTop: "130px",
              marginLeft: isMobile ? "auto" : "initial",
              marginRight: isMobile ? "auto" : "initial",
            }}
          >
            <Stack direction="column">
              <Box component="form">
                <Item>
                  <Typography
                    style={{
                      color: "#676a6c",
                      fontFamily: "sans-serif",
                      marginTop: "10px",
                      fontSize: "24px",
                      fontWeight: 0,
                    }}
                  >
                    {TEXT.FORGOTPWD}
                  </Typography>
                </Item>
                {ShowAlert && ErrorMessage && (
                  <Item>
                    <Alert severity="error" style={{ color: "#a94442" }}>
                      {ErrorMessage}
                    </Alert>
                  </Item>
                )}
                <Item>
                  <Typography
                    style={{
                      color: "#676A6C",
                      fontFamily: "sans-serif",
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginLeft: "10px",
                      marginBottom:"10px"
                    }}
                  >
                    {TEXT.EMAIL}
                  </Typography>
                  <PheonixTextField
                    id=""
                    type="email"
                    value={emails}
                    placeholder="Email"
                    variant="outlined"
                    onChange={handleemailChange}
                    style={{
                      width: isMobile ? "260px" : "350px",
                      
                      height: "38px",
                      color: "#676a6c",
                      fontFamily: "open-sans",
                    }}
                  />
                  {Error && (
                    <Typography
                      style={{
                        color: "#a94442",
                        fontFamily: "sans-serif",
                        fontSize: "12px",
                        textAlign: "left",
                        marginLeft: "10px",
                      }}
                    >
                      {Error}
                    </Typography>
                  )}
                </Item>

                <Item>
                  <SubmitButton
                    label={"Reset Password"}
                    style={{
                      width: isMobile ? "260px" : "350px",
                      textTransform: "none",
                      color: "#FFFFFF",
                      backgroundColor: "#1ab394",
                    }}
                    onClick={handleResetpwd}
                  />
                </Item>

                <Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    textAlign: "center",
                  }}
                >
                  <a
                    style={{
                      marginRight: "15px",
                      fontFamily: "sans-serif",
                      fontSize: "13px",
                      color: "#428bca",
                      textDecoration: "none",
                      marginBottom: "5px",
                    }}
                    onClick={handleLogout}
                  >
                    {TEXT.LOGIN}
                  </a>
                  <a
                    style={{
                      marginRight: "15px",
                      fontFamily: "sans-serif",
                      fontSize: "13px",
                      color: "#428bca",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={handlesignup}
                  >
                    {TEXT.NOACC}
                  </a>
                </Item>
              </Box>
            </Stack>
          </PheonixPaper>
        </PheonixContainer>
      </div>
    </>
  );
};
export default Forgotpwd;
