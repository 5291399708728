import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Divider,
  Autocomplete,
  Chip,
} from "@mui/material";
import { PheonixDataGrid } from "../Components/PhenoixDataGrid";
import MenuItem from "@mui/material/MenuItem";
import { BUTTON, MODALTEXT, TEXT } from "../constant";
import CustomModal from "../Components/PheonixModal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { config } from "../config";
import PheonixTextField from "../Components/PheonixTextField";
import { useNavigate, useLocation } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PheonixContactModal from "../Components/PheonixContactModel";
import { Description } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import PheonixMoveModel from "../Components/PheonixMoveModel";
import { api } from "../api";
import { idText, isSyntheticExpression } from "typescript";
import { StringDecoder } from "string_decoder";
import {
  GridRowId,
  GridValidRowModel,
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import Sidebar from "./sidebar";
import PheonixListModal from "../Components/PheonixListModel";
import { useTheme, useMediaQuery } from "@mui/material";
import PheonixDeleteModal from "../Components/PheonixDeleteModel";
import { useSidebar } from "./sidebarcontext"
import PheonixEditListModal from "../Components/PheonixEditlistmodel";

interface CustomerOption {
  Name: string;
  Description: string;
}
interface Option {
  name: string;
  id: string;
}
export interface ContactOption {
  "First Name": string;
  "Last Name": string;
  Phone: string;
  Email: string;
  List: string;
}

interface Contact {
  id: number;
  first_name: string;
  last_name: string;
  number: string;
  email: string;
  notes: string;
}

interface AllContactsProps {
  onDataSend: (data: string) => void;
}

const AllContacts: React.FC<AllContactsProps> = ({ onDataSend }) => {
  const token = localStorage.getItem("authtoken");
  const [listname, setListName] = useState("");
  const [listdescription, setListDescription] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [list, setList] = useState("");
  const [contacts, setContacts] = useState([]);
  const [ids, setIds] = useState([]);
  const location = useLocation();
  const [listId, setListId] = useState<number>(0);
  const [contact, setContact] = useState<string | null>(null);
  const Title = `List: ${contact}`;
  const deleteTitle = contact;
  const [Options, setOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isNewContactModalOpen, setIsNewContactModalOpen] = useState(false);
  const [isMoveOpen, setIsMoveOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState<CustomerOption>({
    Name: "",
    Description: "",
  });
  const [checkData, setCheckData] = useState<CustomerOption>({
    Name: "",
    Description: "",
  });
  const [editFormData, setEditFormData] = useState<CustomerOption>({
    Name: "",
    Description: "",
  });
  const navigate = useNavigate();
  const [contactformData, setContactFormData] = useState<ContactOption>({
    "First Name": "",
    "Last Name": "",
    Phone: "",
    Email: "",
    List: "",
  });
  const [shouldFetchLists, setShouldFetchLists] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [names, setNames] = useState<Option[]>([]);
  const [selectedName, setSelectedName] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [deletemodal, setDeleteModal] = useState(false);
  const handleConfirmContact = () => {
    handleNewContact();
  };
  const { isOpen, toggleSidebar } = useSidebar();
  useEffect(() => {
    if (location.state?.listId) {
      setListId(location.state.listId);
    }
    if (location.state?.contact?.name || location.state?.contact?.title) {
      setContact(location.state.contact.name || location.state.contact.title);
    }
  }, [location.state]);

  const handleConfirm = () => {
    handleEditList(listId, formData.Name, formData.Description);
    handleCloseModal();
  };

  const handleConfirmed = () => {
    setFormData({
      Name: "",
      Description: "", 
    });
    handleNewList();
    handleCloseModal();
  };

  const handleConfirmMove = () => {
    handleCloseModal();
  };
  const [Message, setMessage] = useState<string>("");
  const [Messages, setMessages] = useState<string>("");
  const [ShowAlert, setShowAlert] = useState(false);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);

  const handleSelect = (selectedIDs: number[]) => {
    setSelectedContacts(selectedIDs);
  };
  const fetchoption = async () => {
    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const lists: Option[] = response.data.lists.map(
        (item: { name: string; id: string }) => ({
          name: item.name,
          id: item.id,
        })
      );

      if (contact && !lists.some((item) => item.name === contact)) {
        lists.unshift({ name: contact, id: "" });
      }

      const userIds = response.data.lists.map(
        (item: { user_id: string }) => item.user_id
      );
      const firstUserId = userIds.length > 0 ? userIds[0] : null;
      if (firstUserId) {
        sessionStorage.setItem("user_id", firstUserId);
      }

      setNames(lists.filter((item) => item.name !== contact));
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  useEffect(() => {
    fetchoption();
  }, [Title]);
  const handleNewList = () => {
    axios
      .post(
        config.baseUrl + "/v1/lists",
        {
          name: formData.Name,
          description: formData.Description,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        const { name, description } = response.data;
        const data = name;
        onDataSend(data);
        fetchoption();
        handleCloseModal();
        setListName("");
        setListDescription("");
        setShowAlert(true);
        setMessage(MODALTEXT.LISTSUCCESS);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
        setFormData({
          Name: "",
          Description: "",
        });
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };

  const fetchListData = (listId: number) => {
    axios
      .get(config.baseUrl + "/v1/lists/" + listId, {
        headers: {
          "x-cotg-authtoken": token,
        },
      })
      .then((response) => {
        const { name, description } = response.data;
        setCheckData({ Name: name, Description: description });
        setEditFormData({ Name: name, Description: description });
        setContact(name);
      })
      .catch((error) => {
        console.error(`${MODALTEXT.ERROR_FETCHING}`, error);
      });
  };

  useEffect(() => {
    if (isEditModalOpen) {
      fetchListData(listId);
    }
  }, [isEditModalOpen]);

  const handleEditList = (
    listId: number,
    name: string,
    description: string
  ) => {
    if (
      editFormData.Name === checkData.Name &&
      editFormData.Description === checkData.Description
    ) {
      setShowAlert(true);
      setMessages(MODALTEXT.NOLISTUPDATE);
      setTimeout(() => {
        setShowAlert(false);
        setMessages("");
      }, 5000);
      return;
    }
    axios
      .put(
        config.baseUrl + "/v1/lists/" + listId,
        {
          name: checkData.Name,
          description: checkData.Description,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        fetchListData(listId);
        if (
          editFormData.Name === name &&
          editFormData.Description === description
        ) {
          setShowAlert(true);
          handleCloseModal();
          setMessages(MODALTEXT.NOLISTUPDATE);
          setTimeout(() => {
            setShowAlert(false);
            setMessages("");
          }, 5000);
        } else {
          const { name, description } = response.data;
          const data = name;
          onDataSend(data);
          fetchListData(listId)
          setShowAlert(true);
          setMessage(MODALTEXT.LISTUPDATE);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
          }, 5000);
        }
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };
  const handleDeleteList = (listId: number) => {
    axios
      .delete(
        config.baseUrl + "/v1/lists/" + listId,

        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        fetchoption();
        handleCloseModal();
        setShowAlert(true);
        setMessage(MODALTEXT.LISTDEL);
        localStorage.setItem("Delete", MODALTEXT.LISTDEL);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
        navigate("/lists");
        window.location.reload();
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };
  const handleRemoveContact = (listId: number, selectedContacts: number[]) => {
    const deleteRequests = selectedContacts.map((contactId) =>
      axios.delete(
        `${config.baseUrl}/v1/lists/${listId}/contact/${contactId}`,
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
    );

    Promise.all(deleteRequests)
      .then((responses) => {
        const deletedContacts = responses.map((response) => response.data);
        const firstNames = deletedContacts.map(
          (contact: any) => contact.first_name
        );
        const firstNamesString = firstNames.join(", ");
        const data = firstNamesString;
        onDataSend(data);
        fetchData(listId);
        handleCloseModal();
        setShowAlert(true);
        setMessage(MODALTEXT.CONTACTDEL);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };

  const handleNewContact = () => {
    if (!contactformData.Phone) {
      setErrorMessage((prev: any) => ({
        ...prev,
        Phone: "Phone number is required.",
      }));
      return;
    }
    axios
      .post(
        config.baseUrl + "/v1/contacts",
        {
          list_ids: contactformData.List,
          first_name: contactformData["First Name"],
          last_name: contactformData["Last Name"],
          number: contactformData.Phone,
          email: contactformData.Email,
        },

        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        const { first_name } = response.data;
        const data = first_name;
        onDataSend(data);
        setShouldFetchLists(true);
        fetchData(listId);
        handleCloseModal();
        setShowAlert(true);
        setMessage(MODALTEXT.CONTACTCREATE);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
        setContactFormData({
          "First Name": "",
          "Last Name": "",
          Phone: "",
          Email: "",
          List: "",
        });
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };

  const handleEditContacts = (row: GridValidRowModel) => {
    const updatedContact: Contact = {
      id: row.id,
      first_name: row.first_name,
      last_name: row.last_name,
      number: row.number,
      email: row.email,
      notes: row.notes,
    };
    axios
      .put(
        `${config.baseUrl}/v1/contacts/${updatedContact.id}`,
        {
          first_name: updatedContact.first_name,
          last_name: updatedContact.last_name,
          number: updatedContact.number,
          email: updatedContact.email,
          notes: updatedContact.notes,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };

  const handlecopy = async () => {
    if (!selectedContacts || selectedContacts.length === 0) {
      handleCloseModal();
      setShowAlert(true);
      setMessages("No contact is selected");
      setTimeout(() => {
        setShowAlert(false);
        setMessages("");
      }, 5000);
      return; 
    } 
    const query = `mutation CopyContacts($targetListId: String!, $contactIds: String, $act: Boolean!) {
      copyContacts(
        targetListId: $targetListId,
        contactIds: $contactIds,
        act: $act,
      ) {
        success,
        message
      }
    }`;

    const variables = {
      targetListId: String(selectedId),
      contactIds: String(selectedContacts),
      act: true,
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );

      const { success, message } = response.data.data.copyContacts;
      if (success) {
        const data = selectedContacts.toString();
        onDataSend(data);
        fetchData(listId);
        setIsMoveOpen(false);
        setShowAlert(true);
        setMessage(MODALTEXT.COPY);
        setSelectedContacts([]);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      } else {
          setSelectedContacts([]);
          setShowAlert(true);
          setMessages(TEXT.COPYERROR);
          setSelectedContacts([]);
          setTimeout(() => {
            setShowAlert(false);
            setMessages("");
          }, 5000);
          setIsMoveOpen(false);
      }
    } catch (error) {
      console.error("ERROR_MESSAGES.ERROR", error);
    }
  };

  const handlemove = async () => {
    if (!selectedContacts || selectedContacts.length === 0) {
      handleCloseModal();
      setShowAlert(true);
      setMessages(TEXT.NO_CONTACT);
      setTimeout(() => {
        setShowAlert(false);
        setMessages("");
      }, 5000);
      return; 
    } 
    const query = `mutation CopyContacts($targetListId: String!, $contactIds: String,$currentListId:String  $act: Boolean!) {
    copyContacts(
      targetListId: $targetListId,
      contactIds: $contactIds,
      currentListId: $currentListId,
      act: $act,
    ) {
      success,
      message
    }
  }`;

    const variables = {
      targetListId: String(selectedId),
      contactIds: String(selectedContacts),
      currentListId: String(listId),
      act: false,
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );

      const { success, message } = response.data.data.copyContacts;
      if (success) {
        const data = selectedContacts.toString();
        onDataSend(data);
        fetchData(listId);
        setIsMoveOpen(false);
        setSelectedContacts([]);
        setShowAlert(true);
        setMessage(MODALTEXT.MOVE);
        setSelectedContacts([]);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      }  else {
        setSelectedContacts([]);
        setShowAlert(true);
        setMessages(TEXT.MOVEERROR); 
        setSelectedContacts([]);
        setTimeout(() => {
          setShowAlert(false);
          setMessages("");
        }, 5000);
        setIsMoveOpen(false);
      }
    } catch (error) {
      console.error("ERROR_MESSAGES.ERROR", error);
    }
  };

  const handleDeleteContact = (selectedContacts: number[]) => {
    axios
      .post(
        config.baseUrl + "/v1/contacts/deletemultiple",
        {
          contact_ids: selectedContacts,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        const data = selectedContacts.toString();
        onDataSend(data);
        fetchData(listId);
        handleCloseModal();
        setShowAlert(true);
        localStorage.setItem("listapi", "false");
        setMessage(MODALTEXT.DELCONTACT);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };

  useEffect(() => {
    if (listId) {
      // @ts-ignore
      fetchData(parseInt(listId));
    }
  }, [listId]);

  const fetchData = async (listId: number) => {
    try {
      const response = await axios.get(
        `${config.baseUrl}/v1/lists/${listId}/contacts`,
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );
      const reversedContacts = response.data.contacts;
      setContacts(reversedContacts);
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  useEffect(() => {
    fetchlistid();
  }, []);
  const fetchlistid = async () => {
    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });
      const ids = response.data.lists.map((item: { id: any }) => item.id);
      setIds(ids);
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  const handleImport = () => {
    navigate("/imports/new");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedOption = names.find(
      (option) => option.name === event.target.value
    );
    setSelectedName(event.target.value);
    if (selectedOption) {
      setSelectedId(selectedOption.id);
    }
    setIsMoveOpen(true);
  };

  const handleOpenModal = (content: React.ReactNode) => {
    setModalContent(content);
    setIsModalOpen(true);
    setDeleteModal(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setIsNewModalOpen(false);
    setIsNewContactModalOpen(false);
    setIsMoveOpen(false);
    setContactFormData({
      "First Name": "",
      "Last Name": "",
      Phone: "",
      Email: "",
      List: "",
    });
    setSelectionModel([]);
    setDeleteModal(false);
  };
  const handleDeleteListWrapper = () => {
    handleDeleteList(listId);
  };
  const column = [
    {
      field: "name",
      headerName: "Name",
      width: isMobile ? 120 : 220,
      editable: true,
      valueGetter: (params: GridValueGetterParams) => {
        const { first_name, last_name } = params.row;
        return `${first_name} ${last_name}`.trim();
      },
      valueSetter: (params: GridValueSetterParams) => {
        const fullName = params.value.split(" ");
        params.row.first_name = fullName[0];
        params.row.last_name = fullName.slice(1).join(" ");
        return params.row;
      },
    },
    { field: "number", headerName: "Phone", width: isMobile ? 120 : 180, editable: true },
    {
      field: "email",
      headerName: "Email",
      width: isMobile ? 150 :250,
      editable: true,
      renderCell: (params: { value: any }) => (
        <span>{params.value || "Add Email Here"}</span>
      ),
    },
    ...(!isMobile
      ? [
          {
            field: "notes",
            headerName: "Notes",
            width: 250,
            editable: true,
            renderCell: (params: { value: any }) => (
              <span>{params.value || "Add Notes Here"}</span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div style={{ top: 0, backgroundColor: "#f3f3f4", height: "130vh" }}>
      { isMobile &&
       <Divider style={{ marginTop: !isOpen && isMobile ? "0.5rem" :isMobile ? "0.5rem" :"auto" }} /> }
      {Message && ShowAlert &&(
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#3c763d" }}
        >
          {Message}
        </Alert>
      )}
      {Messages && ShowAlert &&(
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#A94342", background: "#F2DEDD" }}
        >
          {Messages}
        </Alert>
      )}
     
     <div
    style={{
    marginLeft: "10px",
    marginTop: "5px",
    display: "flex",
    flexDirection: !isOpen && isMobile ? "row":isMobile ? "row" : "row",
    justifyContent: "space-between",
    alignItems: isMobile ? "flex-start" : "center",
  }}
>
  <div
    style={{
      display: "flex",
      flexDirection:  !isOpen && isMobile ? "column":isMobile  ? "column" : "row", 
      alignItems: isMobile  ? "flex-start" : "center",
    }}
  >
    <div style={{ marginBottom: isMobile ? "5px" : "0" }}>
      <Button
        variant="contained"
        onClick={() => setIsEditModalOpen(true)}
        size="small"
        sx={{
          fontWeight: "normal",
          fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
          border: "none",
          borderRadius: "0",
          color: "#676a6c",
          textTransform: "none",
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        {BUTTON.EDIT_LIST}
      </Button>
      <Button
        variant="contained"
        onClick={() => setDeleteModal(true)}
        size="small"
        sx={{
          fontWeight: "normal",
          fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
          marginLeft:!isOpen && isMobile ? "5px": isMobile ? "5px" : "auto",
          border: "none",
          borderRadius: "0",
          textTransform: "none",
          color: "#ffffff",
          backgroundColor: "#ed5565",
          "&:hover": {
            backgroundColor: "#ed5565",
          },
        }}
      >
        {BUTTON.DELETE_LIST}
      </Button>
    </div>

 <div style={{ marginBottom: isMobile ? "10px" : "0" }}>
      <Button
        variant="contained"
        onClick={() => setIsNewModalOpen(true)}
        size="small"
        sx={{
          fontWeight: "normal",
          fontSize: !isOpen && isMobile ? "11px": isMobile ? "12px" : "14px",
          border: "none",
          borderRadius: "0",
          color: "#ffffff",
          textTransform: "none",
          backgroundColor: "#1ab394",
          "&:hover": {
            backgroundColor: "#1ab394",
          },
        }}
      >
        {BUTTON.NEW_LIST}
      </Button>
    </div>

    <Select
      value={selectedValue}
      onChange={handleSelectChange}
      variant="standard"
      displayEmpty
      sx={{
        marginLeft:!isOpen && isMobile ? "0px": isMobile ? "0px" : "10px",
        marginTop: "auto",
        width:!isOpen && isMobile ? "130px": isMobile ? "130px" : "200px",
        backgroundColor: "#FFFFFF",
        color: "#676a6c",
        fontSize: !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
      }}
    >
      <MenuItem
        value=""
        sx={{
          fontSize: !isOpen && isMobile ? "11px" : isMobile ? "12px" : "14px",
          height: !isOpen && isMobile ? "0px" : isMobile ? "0px" : "30px",
        }}
      >
        <em>Move Contacts to</em>
      </MenuItem>
      {names.map((option, index) => (
        <MenuItem
          key={index}
          value={option.name}
          sx={{
            fontSize: !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
            height: !isOpen && isMobile ? "0px" : isMobile ? "0px" : "30px",
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </Select> 
    <PheonixMoveModel
            open={isMoveOpen}
            onClose={() => setIsMoveOpen(false)}
            onConfirm={handlemove}
            onMove={handlecopy}
          />
  </div>

  <div
    style={{
      display: "flex",
      flexDirection: isMobile  ? "column" : "row", 
      alignItems: "flex-end"
    }}
  >
    <div style={{ marginBottom: isMobile ? "5px" : "0" }}>
      <Button
        variant="contained"
        onClick={() => setIsNewContactModalOpen(true)}
        size="small"
        sx={{
          fontWeight: "normal",
          fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
          border: "none",
          borderRadius: "0",
          color: "#ffffff",
          textTransform: "none",
          backgroundColor: "#1ab394",
          "&:hover": {
            backgroundColor: "#1ab394",
          },
        }}
      >
        {BUTTON.NEWCONTACT}
      </Button>
    </div>

<div style={{ marginBottom: isMobile ? "5px" : "0" }}>
      <Button
        variant="contained"
        onClick={handleImport}
        size="small"
        sx={{
          fontWeight: "normal",
          fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
          border: "none",
          borderRadius: "0",
          textTransform: "none",
          color: "#676a6c",
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "#ffffff",
          },
        }}
      >
        {BUTTON.IMPORT}
      </Button>
    </div>
    <div style={{ 
           display: "flex",
           flexDirection: isMobile || isTablet ? "column" : "row", 
           alignItems: isMobile || isTablet ? "flex-end" : "center",
        }}>
    {selectedContacts.length !== 0 && !isTablet && (
      <>
       <div style={{ marginBottom: isMobile ? "5px" : "0" }}>
          <Button
            variant="contained"
            onClick={() => handleDeleteContact(selectedContacts)}
            size="small"
            sx={{
              fontWeight: "normal",
              fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
              border: "none",
              borderRadius: "0",
              textTransform: "none",
              color: "#ffffff",
              backgroundColor: "#ed5565",
              "&:hover": {
                backgroundColor: "#ed5565",
              },
            }}
          >
            {BUTTON.DELETE_CONTACT}
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => handleRemoveContact(listId, selectedContacts)}
            size="small"
            sx={{
              fontWeight: "normal",
              fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
              border: "none",
              marginTop: isTablet ? "20px" : "0",
              borderRadius: "0",
              textTransform: "none",
              color: "#ffffff",
              backgroundColor: "#ed5565",
              "&:hover": {
                backgroundColor: "#ed5565",
              },
            }}
          >
            {BUTTON.REMOVE_CONTACT}
          </Button>
          </div>
      </>
    )}
    </div> 
  </div>
</div>
{
      <div style={{ 
        display: "flex",
        flexDirection: isMobile || isTablet ? "column" : "row", 
        alignItems: isMobile || isTablet ? "flex-end" : "center",
     }}>
 {selectedContacts.length !== 0 && isTablet && (
   <>
    <div>
       <Button
         variant="contained"
         onClick={() => handleDeleteContact(selectedContacts)}
         size="small"
         sx={{
           fontWeight: "normal",
           fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
           border: "none",
           borderRadius: "0",
           textTransform: "none",
           color: "#ffffff",
           backgroundColor: "#ed5565",
           "&:hover": {
             backgroundColor: "#ed5565",
           },
         }}
       >
         {BUTTON.DELETE_CONTACT}
       </Button>
     </div>
     <div>
       <Button
         variant="contained"
         onClick={() => handleRemoveContact(listId, selectedContacts)}
         size="small"
         sx={{
           fontWeight: "normal",
           fontSize: !isOpen && isMobile ? "10px": isMobile ? "12px" : "14px",
           border: "none",
           borderRadius: "0",
           textTransform: "none",
           color: "#ffffff",
           backgroundColor: "#ed5565",
           "&:hover": {
             backgroundColor: "#ed5565",
           },
         }}
       >
         {BUTTON.REMOVE_CONTACT}
       </Button>
       </div>
   </>
 )}
 </div> 
}

      <Box sx={{ boxShadow: "none" }}>
        <PheonixDataGrid
          columns={column}
          rows={contacts}
          checkboxSelections={true}
          rowSelectionModel={selectedContacts}
          onSelect={handleSelect}
          onEdit={handleEditContacts}
          pageSizeOptions={[10, 20, 30]}
          style={{
            border: "none",
            borderColor: "transparent",
            borderStyle: "solid solid none",
            backgroundColor: "#FFFFFF",
            marginLeft: "10px",
            marginTop: "10px",
            marginRight: "10px",
            overflow: "auto",
            maxHeight: "100vh",
            height: "auto",
          }}
          title={Title}
        />
      </Box>
      <CustomModal open={isModalOpen} handleClose={handleCloseModal}>
        {modalContent}
      </CustomModal>
      <PheonixEditListModal
        open={isEditModalOpen}
        onClose={handleCloseModal}
        title={"Edit List"}
        fields={[
          {
            label: "Name",
            type: "text",
            value: checkData.Name,
            onChange: (e) => setCheckData({ ...checkData, Name: e.target.value }),
          },
          {
            label: "Description",
            type: "text",
            value: checkData.Description,
            onChange: (e) =>
              setCheckData({ ...checkData, Description: e.target.value }),
          },
        ]}
        onConfirm={handleConfirm}
        confirmButtonText={BUTTON.UPDATE}
        formData={checkData}
        setFormData={setCheckData}
        isEditModal={true}
      />
      <PheonixListModal
        open={isNewModalOpen}
        onClose={handleCloseModal}
        title={"New List"}
        fields={[
          {
            label: "Name",
            type: "text",
            value: formData.Name,
            onChange: (e) => setFormData({ ...formData, Name: e.target.value }),
          },
          {
            label: "Description",
            type: "text",
            value: formData.Description,
            onChange: (e) =>
              setFormData({ ...formData, Description: e.target.value }),
          },
        ]}
        onConfirm={handleConfirmed}
        confirmButtonText={BUTTON.CREATE}
        formData={formData}
        setFormData={setFormData}
        isEditModal={true}
      />
      <PheonixContactModal
        open={isNewContactModalOpen}
        onClose={handleCloseModal}
        title={"New Contact"}
        fields={[
          {
            label: "First Name",
            type: "text",
            value: contactformData["First Name"],
            onChange: (e) =>
              setContactFormData({
                ...contactformData,
                "First Name": e.target.value,
              }),
          },
          {
            label: "Last Name",
            type: "text",
            value: contactformData["Last Name"],
            onChange: (e) =>
              setContactFormData({
                ...contactformData,
                "Last Name": e.target.value,
              }),
          },
          {
            label: "Phone",
            type: "text",
            value: contactformData.Phone,
            onChange: (e) =>
              setContactFormData({ ...contactformData, Phone: e.target.value }),
          },
          {
            label: "Email",
            type: "text",
            value: contactformData.Email,
            onChange: (e) =>
              setContactFormData({ ...contactformData, Email: e.target.value }),
          },
          {
            label: "List",
            type: "autocomplete",
            value: contactformData.List,
            onChange: (e) =>
              setContactFormData({ ...contactformData, List: e.target.value }),
          },
        ]}
        onConfirm={handleConfirmContact}
        confirmButtonText={BUTTON.CREATE}
        formData={contactformData}
        setFormData={setContactFormData}
        isEditModal={true}
        error={!!errorMessage}
        helperText={errorMessage}
      />
      <PheonixDeleteModal
        open={deletemodal}
        onClose={handleCloseModal}
        onConfirm={handleDeleteListWrapper}
        entity={deleteTitle}
      />
    </div>
  );
};

export default AllContacts;
