import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './global.css';
import { ThemeProvider } from '@mui/material';
import theme from "./theme";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { config } from './config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const googleClientId = config.googleClientId || '';
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={googleClientId}>
    <App />
    </GoogleOAuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);


