import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PheonixButton from "./PheonixButton";
import PheonixDeleteModalProps from "../interfaces/PheonixDeleteModal";
import { MODALTEXT } from "../constant";
import { useTheme, useMediaQuery } from "@mui/material";

const PheonixDeleteModal: React.FC<PheonixDeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  entity,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
          width: isMobile ? "90%" :"30%",
          height: "auto",
          boxShadow: "0px 4px 4px 0px #00000040",
          border: "1px solid #0000001F",
          margin:isMobile? "0px 0px 350px 0px": "0px 10px 350px 100px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "24px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#676a6c",
        }}
      >
        {MODALTEXT.DELLIST}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 3,
            top: 0,
            color: "#999c9e",
          }}
        >
          <CloseIcon sx={{ color: "#999c9e",fontSize: "1.2rem" }} />
        </IconButton>
      </DialogTitle>
      <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
      <DialogContent
        style={{
          display: "inline-flex",
          fontSize: "16px",
          color: "#676a6c",
          letterSpacing: "0.15px",
          fontWeight: 400,
          paddingTop: "4px",
          paddingBottom: "30px",
          marginLeft: "-5px",
        }}
      >
        {MODALTEXT.DELETECONFIRM} {entity} list?
      </DialogContent>
      <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
      <DialogActions>
        <Button
          onClick={onClose}
          size="small"
          sx={{
            fontWeight: "normal",
            fontSize: "14px",
            border: "none",
            borderRadius: "0",
            textTransform: "none",
            color: "#ffffff",
            backgroundColor: "#ed5565",
            marginTop:"-20px",
            "&:hover": {
              backgroundColor: "#ed5565",
            },
          }}
        >
          {MODALTEXT.NO}
        </Button>
        <Button
          onClick={onConfirm}
          size="small"
          sx={{
            fontWeight: "normal",
            fontSize: "14px",
            border: "none",
            borderRadius: "0",
            color: "#ffffff",
            textTransform: "none",
            backgroundColor: "#1ab394",
            marginTop:"-20px",
            "&:hover": {
              backgroundColor: "#1ab394",
            },
          }}
          style={{ marginLeft: "8px" }}
        >
          {MODALTEXT.YES}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PheonixDeleteModal;
