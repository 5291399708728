import React, { createContext, useState, useEffect, useContext } from "react";
import { SessionContextProviderProps } from "../interfaces/ContextInterface";

interface SessionContextType {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

const SessionContextProvider: React.FC<SessionContextProviderProps> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, []);

  const login = () => {
    sessionStorage.setItem("isLoggedIn", "true");
    setIsLoggedIn(true);
  };

  const logout = () => {
    sessionStorage.setItem("isLoggedIn", "false");
    setIsLoggedIn(false);
  };

  return (
    <SessionContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = (): SessionContextType => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("ERROR_MESSAGES.SESSION_ERROR");
  }
  return context;
};

export { SessionContextProvider, useSession };
